import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/core/services/util.service';
import { BoxoutEndpointService } from 'src/app/shared/services/common/boxout-endpoint.service';
import { CurrentThemeService } from 'src/app/shared/services/common/current-theme.service';
import { GTMService } from '../../services/common/gtm.service';
import { AddEditFavoriteListComponent } from '../add-edit-favorite-list/add-edit-favorite-list.component';
import { EmarsysTrackingService } from '../../services/common/emarsys-tracking.service';

@Component({
  selector: 'app-wishlist-list',
  templateUrl: './wishlist-list.component.html'
})
export class WishlistListComponent implements OnInit, OnDestroy {

  copyToListFailure:boolean=false;
  copyToListSuccess:boolean=false;

  wishlists: any[] = [];
  selectedList: string = '';
  productCode: string;
  errorResponse: boolean = false;
  productExistsInList: boolean = false;
  header: string;
  createWishlistDialog: any;
  themeClass: any;
  saveProductToListSub: Subscription;
  getWishlistsSub: Subscription;
  errors: any[] =[];
  action:any;
  gaItems: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private customHttp: BoxoutEndpointService,
    public dialog: MatDialog, 
    private theme: CurrentThemeService, 
    private dialogRef: MatDialogRef<WishlistListComponent>,
    private utils: UtilService, private gtmService: GTMService,
    private emarsysTrackingService: EmarsysTrackingService
    ) { 
    this.themeClass = this.theme?.getCurrentTheme();
  }

  ngOnInit(): void {
    this.productCode = this.data && this.data.productCode;
    this.action = this.data?.action
    this.header = this.data && this.data.header;
    this.gaItems = this.data?.gaItem;
    this.getWishlistsData();
  }

  getWishlistsData() {
    this.getWishlistsSub = this.getWishlists()
      .subscribe((res: any) => {
        if (res && res.wishList) {
          this.wishlists = res.wishList;
        }
      });
  }


  getWishlists() {
    const apiURL = `users/current/wishlists/getWishListName?fields=BASIC`;
    return this.customHttp.get(apiURL);
  }

  saveProduct() {
    if(this.selectedList && this.productCode) {
      this.saveProductToListSub = this.saveProductToList().subscribe( (res: any) => {
        if(res && res.success && this.gaItems?.length) {
          this.gtmService.setAddToWishlist(this.gaItems);
          this.emarsysTrackingService.trackFavoriteProduct(this.gaItems);
        }
        if(res && res.success && res.success === this.utils.translate('account.wishLists.addedToMsg') + this.selectedList) {
          this.dialogRef.close();
        } else if(res && res.success && res.success === this.utils.translate('account.wishLists.success')) {
          this.dialogRef.close();
        }else if(res && res.success && res.success === this.utils.translate('account.wishLists.existsInMsg') + this.selectedList) {
          this.productExistsInList = true;
          this.errorResponse = false;
        } else if (res && res.success && res.success === this.utils.translate('account.wishLists.failMsg') &&
          res.errors && res.errors.errors && res.errors.errors.length) {
            this.errors = res.errors.errors;
        }
      },
      (error) => {
        this.productExistsInList = false;
        this.errorResponse  = true;
      }, () => {
        this.utils.unSubscribeTranslate();
      })
    }
    else if(this.action === 'copySelectedToFavoriteToExistingList'){
      this.copySelectedToFavoriteToExistingList();
    }
  }

  // This method is used to copy selected favorite products to existing wishlist
  copySelectedToFavoriteToExistingList(){
     let entries =this.data?.entries;
     const apiURL = `users/${this.data?.displayUid}/wishlists/copyWishList/${this.selectedList}?fields=DEFAULT`;
     this.customHttp.post(apiURL, {entries}).subscribe((resp: any) => {
      
      if(resp?.success === "Success"){
        this.copyToListSuccess=true;
        this.copyToListFailure=false;
        this.dialogRef.close({name: this.selectedList , action: "copySelectedToFavoriteToExistingListSaved"});
      }else if(resp?.success === "Fail"){
        this.copyToListSuccess=false;
        this.copyToListFailure=true;
      }

    },
    (error:any)=>{
      
    });
  }

  saveProductToList() {
    const apiURL = `users/current/wishlists/addProductToWishList?fields=DEFAULT&productCode=${this.productCode}&wishlistname=${this.selectedList}`;
    return this.customHttp.post(apiURL);
  }

  navigateToCreateNewList() {
    this.dialogRef.close();
    let data: any;
    if(this.productCode) {
      data = {
        isEdit: false,
        isCreator: true,
        productCode: this.productCode,
        gaItems: this.gaItems
      }
    } else {
      data = {
        isEdit: false,
        isCreator: true,
        action: "copySelectedToFavoriteToNewList",
        entries: this.data?.entries
      }
    }
    this.createWishlistDialog = this.dialog.open(AddEditFavoriteListComponent, {
      position: {
        right: '0px',
        top: '0px'
      },
      panelClass: ['wishlist-drawer', this.themeClass],
      data
    });
  }

  ngOnDestroy(): void {
    if(this.getWishlistsSub){
      this.getWishlistsSub.unsubscribe();
    }
    if(this.saveProductToListSub){
      this.saveProductToListSub.unsubscribe();
    }
  }
  setWishList(list:any){
    this.selectedList = list;
  }

}
