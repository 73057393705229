<div class="d-flex flex-column add-edit-card-dialog">
  <div class="d-flex flex-row justify-content-between mb-4">
    <div class="t-title-l" *ngIf="data.addCardFlag">{{ "account.payment.addEditCard.addTitle" | cxTranslate }}
    </div>
    <div class="t-title-l" *ngIf="!data.addCardFlag">{{ "account.payment.addEditCard.editTitle" | cxTranslate }}
    </div>
    <div>
      <mat-icon [mat-dialog-close]="true" class="close-icon dialogCloseIcon" >close</mat-icon>
    </div>
  </div>
  <div class="t-title-s mb-4">{{"account.payment.addEditCard.cardInfoTitle" | cxTranslate }}</div>

  <section class="d-flex flex-column add-edit-section">
    <form [formGroup]="createAddEditCardForm" (submit)="updateInfoAddCardClickFunction()">
      <div class="add-edit-section-input-common mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.payment.addEditCard.nickname" | cxTranslate }}
          </mat-label>
          <input matInput type="text" class="add-edit-section-nickname" formControlName="cardNickName" maxlength="50" />
        </mat-form-field>
      </div>
      <div class="add-edit-section-input-common mb-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "account.payment.addEditCard.nameOnCard" | cxTranslate }} *
          </mat-label>
          <input matInput type="text" class="add-edit-section-nameoncard" formControlName="nameOnCard" maxlength="60" />
          <mat-error *ngIf="createAddEditCardForm.get('nameOnCard')?.touched &&
           createAddEditCardForm.get('nameOnCard')?.hasError('required')">{{ "account.payment.addEditCard.nameOnCardRequired" | cxTranslate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="add-edit-section-input-common mb-2">
        <div *ngIf="data.addCardFlag">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "account.payment.addEditCard.cardNumber" | cxTranslate }} *
            </mat-label>
            <input matInput type="text" class="add-edit-section-cardnumber" formControlName="cardNumber"
              (blur)="onBlurCardNumber($event)" maxlength="20" />
            <mat-error *ngIf="createAddEditCardForm.get('cardNumber')?.touched &&
             createAddEditCardForm.get('cardNumber')?.hasError('required')">
              {{ "account.payment.addEditCard.cardNumberRequired" | cxTranslate }}
            </mat-error>
            <mat-error *ngIf="createAddEditCardForm.get('cardNumber')?.touched &&
             createAddEditCardForm.get('cardNumber')?.hasError('cardNumberInvalid')">
              {{ "account.payment.addEditCard.cardNumberTokenInvalid" | cxTranslate }}
            </mat-error>
            <mat-error *ngIf="createAddEditCardForm.get('cardNumber')?.touched &&
             createAddEditCardForm.get('cardNumber')?.hasError('cardTypeInvalid')">
              {{ "account.payment.addEditCard.cardTypeInvalid" | cxTranslate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!data.addCardFlag">
          <p class="t-title-l">
            {{ "account.payment.addEditCard.cardTxt" | cxTranslate }}: {{data?.paymentData.cardNumber}}
          </p>
        </div>
      </div>
      <div class="t-title-s mb-4">
        {{ "account.payment.addEditCard.expDateTitle" | cxTranslate }}
      </div>
      <div class="d-flex flex-column">
        <div class="expiry-fields-section d-flex flex-md-row flex-column justify-cotent-between">

          <div class="d-flex flex-column">
            <div class="expiry-section d-flex flex-md-row flex-column">
              <div class="add-edit-section-month mr-2 mr-md-3">
                <mat-form-field appearance="outline">
                  <mat-label appRemoveAriaOwns>{{"account.payment.addEditCard.monthTitle" | cxTranslate}} *</mat-label>
                  <mat-select formControlName="expiryMonth">
                    <mat-option *ngFor="let month of months" [value]="month">
                      {{ month }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="add-edit-section-month mr-2 mr-md-3">
                <mat-form-field appearance="outline">
                  <mat-label appRemoveAriaOwns>{{"account.payment.addEditCard.yearTitle" | cxTranslate}} *</mat-label>
                  <mat-select formControlName="expiryYear">
                    <mat-option *ngFor="let year of yearRange" [value]="year">
                      {{ year }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="cvv-section">
            <mat-form-field appearance="outline" class="">
              <mat-label>{{ "account.payment.addEditCard.cvv" | cxTranslate }} *
              </mat-label>
              <input matInput type="text" class="add-edit-section-cvv" formControlName="issueNumber" maxlength="5" />
              <mat-error *ngIf="createAddEditCardForm.get('issueNumber')?.touched && createAddEditCardForm.get('issueNumber')?.hasError('pattern')">{{"account.payment.addEditCard.issueNumberPattern" | cxTranslate}}</mat-error>
              <mat-error *ngIf="createAddEditCardForm.get('issueNumber')?.touched && createAddEditCardForm.get('issueNumber')?.hasError('required')">{{"account.payment.addEditCard.issueNumberRequired" | cxTranslate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="expiry-error-section">
          <mat-error class="" *ngIf="(createAddEditCardForm.get('expiryYear')?.touched &&
            createAddEditCardForm.get('expiryYear')?.hasError('required')) ||
            (createAddEditCardForm.get('expiryMonth')?.touched &&
            createAddEditCardForm.get('expiryMonth')?.hasError('required')) ">
            {{ "account.payment.addEditCard.expiryRequired" | cxTranslate }}
          </mat-error>
          <mat-error *ngIf="(createAddEditCardForm.get('expiryYear')?.touched &&
            createAddEditCardForm.get('expiryYear')?.hasError('expNotValid')) ||
            (createAddEditCardForm.get('expiryMonth')?.touched &&
            createAddEditCardForm.get('expiryMonth')?.hasError('expNotValid')) ">
            {{ "account.payment.addEditCard.validExpiryRequired" | cxTranslate }}
          </mat-error>

        </div>
      </div>
      <div class="billing-address-section mt-4 mb-4">

        <div class="t-title-s mb-4">{{"account.payment.addEditCard.billingAddressTitle" | cxTranslate }}</div>
        <div *ngIf="showAddressFailure">
          <reusable-alert-box [alertType]="'warningAlertBox'"
            alertMsg="{{'account.manageBusinessAcct.infoUpdateError' | cxTranslate }}"></reusable-alert-box>
        </div>

        <div *ngIf="showAddressSuccess">
          <reusable-alert-box [alertType]="'successAlertBox'"
            alertMsg="{{ 'account.manageBusinessAcct.infoUpdateSuccess' | cxTranslate }}"></reusable-alert-box>
        </div>
        <div class="showSelectedAddress" [hidden]="showAddAddress">
          <p class="t-body-l mb-4 selectedAddrTxt">
            {{billingAddress}}
          </p>
          <button type="button" mat-flat-button color="white" class="addAddressBtn mr-3" (click)="addNewBillingAddrClick($event)">
            <mat-icon svgIcon='plus' class="default-payment-add-addr"></mat-icon>&nbsp;&nbsp; {{ "account.payment.addEditCard.addNewAddressTxt" |
            cxTranslate }}
          </button>
        </div>
        <div class="addNewAddrSection" [hidden]="!showAddAddress">
          <app-reusable-address-form></app-reusable-address-form>
          <div class="addAddrBtnHolder d-flex justify-content-end">
            <button class="t-label-l payment-cancel-button mr-2" mat-flat-button color="white"
              (click)="showAddAddress = !showAddAddress">
              {{ 'account.cancel' | cxTranslate }}
            </button>
            <button type="button" class="t-label-l add-address-btn" mat-raised-button color="primary"
              (click)="addNewAddrFormClick($event)">
              {{ "account.payment.addEditCard.addAddress" | cxTranslate }}
            </button>
          </div>
        </div>

        <div class="t-body-l warning-section my-4"><span class="warning-color">{{"account.payment.addEditCard.billingAddressImpText" | cxTranslate }} </span> {{"account.payment.addEditCard.billingAddressImpWarning" | cxTranslate }}</div>
      </div>
      <div class="default-payment-section mb-4 pt-1">
        <p class="t-title-s mb-3">
          {{ "account.payment.addEditCard.defaultCardTitle" | cxTranslate }}
        </p>
        <mat-slide-toggle class="default-payment-toggle t-label-l" color="primary" (change)="checkToggle($event)"
          formControlName="defaultPayment">
          {{ toggleStatus }}
        </mat-slide-toggle>
      </div>
      <div class="addEditPaymentBtnSection pt-3">
        <div class="d-flex justify-content-end" *ngIf="data.addCardFlag">
          <button class="t-label-l" type="submit" mat-raised-button color="primary">
            {{ "account.payment.addEditCard.addPaymentBtn" | cxTranslate }}
          </button>
        </div>
        
      </div>
    </form>
    <div class="d-flex justify-content-between" *ngIf="!data.addCardFlag">
      <button class="t-label-l payment-add-edit-button" mat-flat-button color="white"
        (click)="removePaymentCard(data?.paymentData)">
        {{ "account.payment.addEditCard.removeCardBtn" | cxTranslate }}
      </button>
      <button class="t-label-l payment-add-edit-button" mat-raised-button color="primary"
        (click)="updateInfoAddCardClickFunction(data?.paymentData)" [disabled]="showAddAddress">
        {{ "account.payment.addEditCard.editPaymentBtn" | cxTranslate }}
      </button>
    </div>
  </section>

  <!-- ReCaptcha Div. -->
  <div style="visibility: hidden;" id="g-recaptcha" class="g-recaptcha" [attr.data-sitekey]="recaptchaKey"
    data-callback="onSubmitRecaptcha">
  </div>
  
</div>